import "./scss/main.scss";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.min.css";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm.js";
import "photoswipe/dist/photoswipe.css";

Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);
window.Swiper = Swiper;
window.PhotoSwipeLightbox = PhotoSwipeLightbox;
window.PhotoSwipe = PhotoSwipe;

require("./js/alpine");
